import React from 'react'
import { Box, Heading } from 'rebass/styled-components'
import { updatePageTimestamp } from '../../models/Pages'

const CollectionView = ({ dispatch }) => {
    const sendUpdatePageTimestamp = () => {
        updatePageTimestamp()
    }

    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box px='xs' pb='xs'>
                    <Heading as="h2" variant="h4" color="greyMedium">Pages DB Functions</Heading>
                </Box>
            </Box>

            <Box onClick={sendUpdatePageTimestamp}>updatePageTimestamp</Box>
        </Box>
    )
}

export default CollectionView
