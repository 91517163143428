import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { Box, Flex, Heading } from 'rebass/styled-components'
import { addPage } from '../../models/Pages'
import PageBuilderHeader from '../../modules/PageBuilderHeader'
import PageBuilder from '../../modules/PageBuilder'
import { setPageDetails, resetPageBuilder } from '../../system/redux/reducers/pagebuilder'
import { saveToggle, savingToggle } from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import ModuleSelectBox from '../../components/pagebuilder/ModuleSelectBox'
import BorderContainer from '../../components/general/BorderContainer'
import PageLoader from '../../components/loaders/PageLoader'

import loadable from '@loadable/component'
const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const PagesAddView = ({ dispatch, auth, author, pageDetails, pageModules, pageMedia, isSaving, isSetting }) => {
    const { register, handleSubmit, formState: { errors }} = useForm()
    useEffect(() => {
        dispatch(resetPageBuilder())
    }, [])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onSubmit = async () => {
        let updateDetails = { ...pageDetails }
        let updateMedia = { ...pageMedia }
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))
        const save = {
            parent: '',
            title: updateDetails.title,
            visibility: updateDetails.visibility,
            media: {
                gallery: {
                    images: updateMedia.galleryImage,
                    docs: updateMedia.galleryDoc,
                    videos: updateMedia.galleryVideo
                },
                set: updateMedia.mediaSet
            },
            content: {
                excerpt: updateDetails.excerpt,
                modules: pageModules
            },
            added_by: author || auth.uid
        }
        
        // We are saving publish data if set to published
        if (updateDetails.visibility !== 'draft') {
            save.published_by = auth.uid
            save.date_published = true
        }

        // We're saving all post data
        addPage(save).then((docRef) => {
            navigate(`/pages/edit/${docRef.data}`)
            dispatch(savingToggle(false))
        })
    }

    return (
        <Box as='form' id="hook-form" onSubmit={handleSubmit(() => {onSubmit() })}>
            <Flex alignItems="center" justifyContent="flex-start">
                <Heading variant="h4" as="h1" mr="lg">
                    <EditableField
                        name="title"
                        placeholder='Enter Title'
                        {...register('title', { required: true })}
                        type='text'
                        value={pageDetails.title}
                        variant="contentEditableInput"
                        onChange={(e) => onInputChange('title', e)}
                        pl="0"
                        errors={errors}
                        errorMessage="Cannot be empty"
                    />
                </Heading>
                <Flex flexGrow={1} height="2px" bg="greyLighter"></Flex>
                {/* <Button variant='primarySmall' ml="md">Preview</Button> */}
            </Flex>
            <Box>
                <Flex>
                    <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                        <Box variant="card" pt="lg" mt="sm">
                            <PageBuilderHeader />
                            <Box px='xs'>
                                {
                                    (pageModules && pageModules.length) ?
                                        <PageBuilder modules={pageModules} />
                                        :
                                        <Box pr='xs' width='100%'>
                                            <BorderContainer>
                                                <ModuleSelectBox index='0' moduleKey='0' />
                                            </BorderContainer>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Flex>
                    {
                        isSetting ?
                            <ModuleSettings />
                            :
                            <RightSideBar onInputChange={onInputChange} section="pages"/>
                    }
                </Flex>
            </Box>
            {
                isSaving ?
                    <PageLoader />
                    : null
            }
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        pageDetails: state.Pagebuilder.details,
        pageDetailExtras: state.Pagebuilder.extras,
        pageModules: state.Pagebuilder.pagebuilder.modules,
        pageMedia: state.Pagebuilder.media,
        auth: state.Auth.userVerified,
        author: state.Pagebuilder.details.added_by,
        isSaving: state.Savehandling.isSaving,
        isSetting: state.Sidebar.isSetting,
    }
}
export default connect(mapStateToProps)(PagesAddView)