import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import { PagesSection } from '../system/settings/pageData'
import PagesEditView from '../views/pages/modify'
import PagesAddView from '../views/pages/add'
import PagesView from '../views/pages/index'
import PagesFunctionsView from '../views/pages/functions'
import Pointer from '../views/pointer'

const PageInfo = {
    parent: PagesSection.section,
    page: 'Add New',
    icon: PagesSection.icon,
}

const PageRoutes = () => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'Functions' }} Component={PagesFunctionsView} minimumRole={PagesSection.minimumRole} path={withPrefix('/pages/functions')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={PagesEditView} minimumRole={PagesSection.minimumRole} path={withPrefix('/pages/edit/:id')} />
            <Pointer PageInfo={PageInfo} Title='Add New' Component={PagesAddView} minimumRole={PagesSection.minimumRole} path={withPrefix('/pages/add')} id='' />
            <Pointer PageInfo={{ ...PageInfo, page: 'All Pages' }} Component={PagesView} minimumRole={PagesSection.minimumRole} path={withPrefix('/pages')} />
        </Router>
    );
}

export default PageRoutes
