import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Flex, Link, Heading, Text } from 'rebass/styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { updatePage, generatePageSlug } from '../../models/Pages'
import PageBuilderHeader from '../../modules/PageBuilderHeader'
import PageBuilder from '../../modules/PageBuilder'
import { setPBDoc, setPageDetails, setPageExtras, setPageMedia, setModules } from '../../system/redux/reducers/pagebuilder'
import { saveToggle, savingToggle } from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import ModuleSelectBox from '../../components/pagebuilder/ModuleSelectBox'
import BorderContainer from '../../components/general/BorderContainer'
import PageLoader from '../../components/loaders/PageLoader'


import { onSnapshot, doc } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

import loadable from '@loadable/component'
const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const PagesModify = ({ dispatch, id, auth, isSaving, pageDetails, pageModules, pageMedia, isSetting }) => {
    const { register, errors, handleSubmit } = useForm()
    const [page, setPage] = useState(false)

    useEffect(() => {
        let isCancelled = false
        onSnapshot(doc(db, "pages", id), (doc) => {
            if (!isCancelled) {
                setPage(doc.data())
            }
        })

        return () => {
            isCancelled = true
        }
    }, [id])

    useEffect(() => {
        if (page) {
            const item = { ...page }

            let spreadModules = []
            if (item.content && item.content.modules) {
                spreadModules = (!Array.isArray(item.content.modules)) ? Object.values(item.content.modules) : [...item.content.modules]
            }
            let published = false
            if (item.date_published) {
                published = {
                    date_published: item.date_published,
                    published_by: item.published_by
                }
            }
            let updated = false
            if (item.date_modified) {
                updated = {
                    date_modified: item.date_modified,
                    edited_by: item.edited_by
                }
            }
            dispatch(setPBDoc(id))
            const extrasData = JSON.stringify({ description: item.content.description || '' })
            dispatch(setPageExtras(extrasData))
            const detailsData = JSON.stringify({
                added_by: item.added_by || '',
                categories: false,
                excerpt: item.content.excerpt || '',
                parent: item.parent || '',
                published,
                slug: item.slug || '',
                tags: false,
                title: item.title || '',
                updated,
                visibility: item.visibility || 'draft'
            })
            dispatch(setPageDetails(detailsData))
            const mediaData = JSON.stringify({
                galleryDoc: item.media && item.media.gallery.docs || [],
                galleryImage: item.media && item.media.gallery.images || [],
                galleryVideo: item.media && item.media.gallery.videos || [],
                mediaSet: item.media && item.media.set || [],
            })
            dispatch(setPageMedia(mediaData))
            dispatch(setModules(spreadModules))
        }
    }, [page])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const constructPermalink = () => {
        let url = process.env.GATSBY_WEB_ADDRESS

        if (pageDetails.parent) {
            url += '/' + pageDetails.parent
        }

        return url += '/'
    }

    const onSubmit = async () => {
        let updateDetails = { ...pageDetails }
        let updateMedia = { ...pageMedia }
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))
        const save = {
            added_by: updateDetails.added_by,
            parent: updateDetails.parent,
            title: updateDetails.title,
            slug: updateDetails.slug,
            visibility: updateDetails.visibility,
            media: {
                gallery: {
                    images: updateMedia.galleryImage,
                    docs: updateMedia.galleryDoc,
                    videos: updateMedia.galleryVideo
                },
                set: updateMedia.mediaSet
            },
            content: {
                ...page.content,
                excerpt: updateDetails.excerpt,
                modules: pageModules
            }
        }

        if (page.slug !== save.slug) {
            save.slug = await generatePageSlug(save.slug)
        }
        
        // We are updating an already existing page
        if (page.visibility === 'draft' && updateDetails.visibility !== 'draft') {
            save.published_by = auth.uid
            save.date_published = true
        }

        // We're updating all post data
        updatePage(id, save).then(() => {
            dispatch(savingToggle(false))
        })
    }

    return (
        <Box>
            <Flex alignItems="center" justifyContent="flex-start">
                <Heading variant="h4" as="h1" mr="lg" flexGrow={1}>
                    <EditableField
                        name="title"
                        placeholder='Enter Title'
                        {...register('title', { required: true })}
                        type='text'
                        value={pageDetails.title}
                        variant="contentEditableInput"
                        onChange={(e) => onInputChange('title', e)}
                        pl="0"
                        errors={errors}
                        errorMessage="Cannot be empty"
                    />
                </Heading>
                {/* <Flex flexGrow={1} height="2px" bg="greyLighter"></Flex> */}
                {/* <Button variant='primarySmall' ml="md">Preview</Button> */}
            </Flex>
            <Box>
                <Flex alignItems="center" justifyContent="flex-start" color="greyMedium" fontSize="md">
                    <Text fontWeight="bold">Permalink:</Text>
                    <Link pl="xxs" href={constructPermalink() + pageDetails.slug + '?preview=true&id=' + id} color="greyMedium" fontSize="lg" target="_blank" sx={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faExternalLinkAlt} /></Link>
                    <Flex pl="xxs" mr='xxs' alignItems='center' flexGrow={1}>
                        {constructPermalink()}
                        <EditableField
                            name="slug"
                            placeholder='Enter Slug'
                            {...register('slug', { required: true })}
                            type='text'
                            value={pageDetails.slug || 'Add Slug'}
                            variant="contentEditableInput"
                            onChange={(e) => onInputChange('slug', e)}
                            pl="0"
                            errors={errors}
                            errorMessage="Cannot be empty"
                        />
                    </Flex>
                    {/* <Button variant='primaryTiny' bg="greyMedium" mx="xxs">Edit</Button> */}
                </Flex>
                <Flex>
                    <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                        <Box variant="card" pt="lg" mt="sm">
                            <PageBuilderHeader />
                            <Box px='xs'>
                                {
                                    (pageModules && pageModules.length) ?
                                        <PageBuilder modules={pageModules} />
                                        :
                                        <Box pr='xs' width='100%'>
                                            <BorderContainer>
                                                <ModuleSelectBox index='0' moduleKey='0' />
                                            </BorderContainer>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Flex>
                    {
                        isSetting ?
                            <ModuleSettings />
                            :
                            <RightSideBar onInputChange={onInputChange} onSubmit={onSubmit} section="pages"/>
                    }
                </Flex>
            </Box>
            {
                isSaving ?
                    <PageLoader />
                    : null
            }
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        pageModules: state.Pagebuilder.pagebuilder.modules,
        pageDetails: state.Pagebuilder.details,
        pageMedia: state.Pagebuilder.media,
        auth: state.Auth.userVerified,
        isSaving: state.Savehandling.isSaving,
        isSetting: state.Sidebar.isSetting,
    }
}
export default connect(mapStateToProps)(PagesModify)