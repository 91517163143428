import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Box, Text } from 'rebass/styled-components'
import Moment from 'react-moment'
import { navigate, Link } from 'gatsby'

import Table from '../../components/tables/Table'
import TableCheckBox from '../../components/tables/TableCheckBox'
// import TableSort from '../../components/tables/TableSort'
import { resetTable, sortTable } from '../../system/redux/reducers/tables'
import Cell from '../../components/tables/Cell'
import Row from '../../components/tables/Row'
// import SaveIcon from '../../components/general/SaveIcon'
import EllipsisControls from '../../components/tables/EllipsisControls'
import EllipsisControlsItems from '../../components/tables/EllipsisControlsItems'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import { duplicateItem, getPages } from '../../models/Pages'
import { setList, resetList } from '../../system/redux/reducers/list'
import { onSnapshot, collection, orderBy, query, Timestamp, getDocs } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import Loading from '../../components/general/Loading'
import { clearFilter } from '../../system/redux/reducers/filter';
import InfiniteScroll from 'react-infinite-scroll-component';

const getCollection = async (collectionName) => {
    const output = []
    const querySnapshot = await getDocs(collection(db, collectionName));
    querySnapshot.forEach((doc) => {
        const docData = doc.data()
        docData.id = doc.id
        output.push(docData)
    })
    return output
}

const PagesView = ({ dispatch, ID, order, list, lastVisible, filter, term }) => {
    const [itemList, updateList] = useState([])
    const [allChecked, checkAll] = useState(false)
    const sortDirection = order[ID] ? order[ID].direction : false
    const sortColumn = order[ID] ? order[ID].col : false
    const template = '0.7fr 2fr 1fr 1.5fr 1.5fr 1.5fr 1fr 0.3fr'
    const [pages, setPages] = useState(false)
    const [users, setUsers] = useState(false)
    
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isCancelled = false

        getCollection('users').then((users) => {
            if (!isCancelled) setUsers(users)
        })

        dispatch(resetTable())
        dispatch(clearFilter())
        dispatch(resetList())

        return () => {
            isCancelled = true
        }
    }, [])

    useEffect(() => {
        if (!lastVisible && filter && !filter.length) {
            fetchMoreData({ first: true })
        }
    }, [lastVisible])

    useEffect(() => {
        setLoading(true)
        fetchMoreData({ first: true })
    }, [pages])

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'pages'), orderBy('date_modified', 'desc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const docData = doc.data()
                    docData.id = doc.id
                    output.push(docData)
                })
                setPages(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    const areYouSure = (id) => {
        const confirmData = JSON.stringify({ isOpen: true, id, modalType: 'DELETE_PAGE', collection: null })
        dispatch(confirmBoxToggle(confirmData))
    }
    const goToEdit = (id) => {
        navigate(`/pages/edit/${id}`)
    }

    const addNew = () => {
        navigate(`/pages/add/`)
    }

    const sortByColumn = (column) => {
        const sortData = JSON.stringify({
            column,
            table: ID,
            sort: ( sortColumn === column && sortDirection === 'asc') ? 'desc' : 'asc',
        })
        dispatch(sortTable(sortData))
    }

    const duplicateThisItem = async (id) => {
        setLoading(true)
        duplicateItem(id)
    }

    const fetchMoreData = async ({ first = false, filter, term }) => {
        const perPage = 15
        let count = list.length / perPage
        let page = count === 0 ? count : count + 1
        let items = [...list]
        let last = lastVisible
        
        if (first) {
            count = perPage
            page = 0
            items = []
            last = false
        }

        const results = await getPages({ page, limitNumber: perPage, lastVisible: last, filter, term })
        if (results.list) {
            Object.entries(results.list).map(([key, item]) => {
                items.push(item)
            })
            const listData = JSON.stringify({
                list: items,
                lastVisible: results.lastVisible,
                filter: results.filter,
                term
            })
            dispatch(setList(listData))
            setLoading(false)
        }
    }

    const headerRow = [
        <Cell className="center" key="checkbox" justifyContent="center">
            <TableCheckBox onPress={() => checkAll(!allChecked)} />
        </Cell>,
        // <Cell key="title" onClick={ () => sortByColumn('title') }><Text>Title</Text><TableSort direction={ ( sortColumn === 'title' ) ? sortDirection : false } /></Cell>,
        <Cell key="title"><Text>Title</Text></Cell>,
        <Cell key="slug"><Text>Slug</Text></Cell>,
        <Cell key="author"><Text>Created By</Text></Cell>,
        <Cell key="created"><Text>Created</Text></Cell>,
        <Cell key="modified"><Text>Modified</Text></Cell>,
        <Cell key="status"><Text>Status</Text></Cell>,
        <Cell key="actions"></Cell>,
    ]

    return (
        <Table title="All Pages" ID={ID} contentType="PAGE" addNew={addNew} template={template} headerRow={headerRow} fetchMoreData={fetchMoreData} algoliaIndice={process.env.GATSBY_ALGOLIA_PAGE_INDICE}>
            { 
                loading ?
                    <Box p='xs'><Loading /></Box> :
                    <InfiniteScroll
                        style={{ overflow: 'initial' }}
                        dataLength={list.length}
                        next={() => fetchMoreData({ first: false, lastVisible, term })}
                        hasMore={pages.length > list.length && !filter?.length ? true : false}
                        loader={pages.length > list.length && !filter?.length ? <Box p='xs'><Loading /></Box> : null}
                    >
                        { users &&
                            Object.entries(list).map(([key, post]) => {
                                const author = users.filter(user => user.uid === post.added_by)[0]
                                return (
                                    <Row key={post.id} py='sm' template={template}>
                                        <Cell className="center">
                                            <TableCheckBox checked={allChecked} doc={post.id} table={ID} />
                                        </Cell>
                                        <Cell fontWeight="bold">
                                            <Link to={`/pages/edit/${post.id}`}>
                                                { post.title }
                                            </Link>
                                        </Cell>
                                        <Cell>
                                            { post.slug }
                                        </Cell>
                                        <Cell>
                                            { author ? author?.first_name + ' ' + author?.last_name : null }
                                        </Cell>
                                        <Cell>
                                            <Moment format="YYYY-MM-DD HH:mm" date={new Timestamp(post.date_added.seconds, post.date_added.nanoseconds).toDate()} />
                                        </Cell>
                                        <Cell>
                                            <Moment format="YYYY-MM-DD HH:mm" date={new Timestamp(post.date_modified.seconds, post.date_modified.nanoseconds).toDate()} />
                                        </Cell>
                                        <Cell>
                                            <Text sx={{ textTransform:'capitalize' }}>
                                                { post.visibility }
                                            </Text>
                                        </Cell>
                                        <Cell className="end">
                                            <EllipsisControls>
                                                <EllipsisControlsItems onClick={() => duplicateThisItem(post.id)}>Duplicate</EllipsisControlsItems>
                                                <EllipsisControlsItems onClick={() => goToEdit(post.id)}>Edit</EllipsisControlsItems>
                                                <EllipsisControlsItems onClick={() => areYouSure(post.id)}>Delete</EllipsisControlsItems>
                                            </EllipsisControls>
                                        </Cell>
                                    </Row>
                                )
                            })
                        }
                    </InfiniteScroll>
            }
        </Table>
    )
}

function mapStateToProps(state) {
    return {
        order: state.Tables.sort,
        ID: state.ID || 'TABLE_PAGES',
        list: state.List.list,
        lastVisible: state.List.lastVisible,
        filter: state.List.filter,
        term: state.List.term,
    }
}

export default connect(mapStateToProps)(PagesView)
